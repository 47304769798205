<template>
  <b-card>
    <brands-list />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import BrandsList from './BrandsList.vue'

export default {
  components: {
    BCard,
    BrandsList,
  },
}
</script>
